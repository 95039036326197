<template>
  <b-row v-if="loading">
    <b-col>
      <b-spinner></b-spinner>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-container class="mt-4">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <BackButton class="mr-2"></BackButton>
          <h4 class="m-0">
            Editing invitation page for client
            <b-link :to="`/onboarding/client/edit/${client.id}`">{{ client.display_name }}</b-link>
          </h4>
        </div>
        <div class="col-12">
          <hr>
        </div>
      </div>
      <div class="p-3">
        <div class="row mt-3">
          <b-col>
            <div class="row">
              <div class="col-4">
                <b-form-group id="input-group-1"
                              label="* Page Title"
                              :invalid-feedback="handleError('page.current.page_title')"
                              :state="isValid('page.current.page_title')"
                              label-for="page-title-input">
                  <b-form-input id="page-title-input"
                                v-model="page.current.page_title"
                                type="text"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group id="input-group-2" label="Page URL"
                              label-for="invite-url-input">
                  <b-form-input disabled id="invite-url-input"
                                v-model="inviteUrl">
                  </b-form-input>
                </b-form-group>
              </div>
            </div>
          </b-col>
        </div>
        <div class="row mt-3">
          <b-col>
            <div class="row">
              <table class="table">
                <thead class="thead">
                <tr>
                  <th scope="col">* Target Client - Service Type</th>
                  <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="type in serviceTypes.current" :key="type.value">
                  <td class="col-md-5">
                    {{ type.text }}
                  </td>
                  <td class="align-middle">
                    <b-button @click="removeClientType(type)"
                              variant="danger">
                      <feather type="trash"></feather>
                    </b-button>
                  </td>
                </tr>
                <tr v-if="!isValid('page.current.service_types')">
                  <td><span class="t3 fc-red">{{ handleError('page.current.service_types') }}</span></td>
                </tr>
                <tr>
                  <td class="col-md-5">
                    <b-form-group id="input-client-types-label" label-for="input-client-types">
                      <b-form-select id="input-client-types"
                                     :disabled="!this.availableClientProvidedServiceTypes.length"
                                     v-model="currentSelectedServiceType"
                                     :options="this.availableClientProvidedServiceTypes"></b-form-select>
                    </b-form-group>
                  </td>
                  <td>
                    <b-button
                        :disabled="!currentSelectedServiceType || !this.availableClientProvidedServiceTypes.length"
                        @click="addClientType"
                        variant="success">
                      Add
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </div>
        <b-row class="mt-2" align-h="center" align-v="center">
          <b-button class="mr-2"
                    @click="cancel"
                    type="button"
                    variant="light">
            Cancel
          </b-button>
          <b-button :disabled="$v.$invalid || saving"
                    @click="saveMemberInvitation"
                    type="submit"
                    class="mr-2"
                    variant="primary">
            Save
          </b-button>
        </b-row>
      </div>
    </b-container>
  </b-row>
</template>

<script>
import { isEmpty, isEqual } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { parseValidationsStr } from '@sword-health/input-validation';
import BackButton from '@/components/BackButton.vue';
import errorHandler from '@/mixins/errorHandler';
import copyToClipboard from '@/scripts/tools/accessibility';

export default {
  name: 'EditMemberInvitation',
  components: {
    BackButton,
  },
  mixins: [ errorHandler ],
  data() {
    return {
      loading: true,
      saving: false,
      page: {
        original: null,
        current: null,
      },
      serviceTypes: {
        original: [],
        current: [],
      },
      availableClientProvidedServiceTypes: [],
      currentSelectedServiceType: null,
    };
  },
  validations() {
    return {
      page: {
        current: {
          page_title: parseValidationsStr({
            required: true,
            maxL: 200,
          }),
        },
      },
      serviceTypes: {
        current: { required },
      },
    };
  },
  computed: {
    userEnv() {
      return this.$store.getters['Core/selectedAPI'];
    },
    clientListOnUserEvn() {
      return this.$store.getters['Core/getClients'] || [];
    },
    client() {
      return this.clientListOnUserEvn.find(c => Number(c.id) === Number(this.$route.params.clientId));
    },
    inviteUrl() {
      const baseUrl = this.$store.getters['Onboarding/getMemberInvitationUrl'].replace(/\/$/, '');
      return `${baseUrl}/c/${this.client.reference}/${this.page.current.page_uuid}`;
    },
  },
  async beforeMount() {
    await this.$store.dispatch('Core/fetchClients');
    this.page.current = await this.$store.dispatch('Onboarding/getMemberInvitation', {
      clientRef: this.client.reference,
      uuid: this.$route.params.uuid,
    });
    this.page.original = Object.assign({}, this.page.current);
    this.loading = false;
    this.mapCurrentClientProvidedServiceTypes();
    this.fillAvailableClientsProvidedServiceTypes();
  },
  methods: {
    mapCurrentClientProvidedServiceTypes() {
      const fetchedClients = {};
      this.page.original.targets.forEach(item => {
        const targetInfo = item.target.split('|');
        const reference = targetInfo[1];
        const service = targetInfo[2];

        let foundClient = fetchedClients[reference];
        if (!foundClient) {
          foundClient = this.clientListOnUserEvn.find(client => client.reference === reference);
          if (foundClient === undefined) {
            return;
          }
          fetchedClients[foundClient.reference] = foundClient;
        }

        this.serviceTypes.current.push({
          text: `${foundClient.display_name} - ${service}`,
          value: item.target,
        });
      });

      this.serviceTypes.original = Object.assign({}, this.serviceTypes.current);
    },
    fillAvailableClientsProvidedServiceTypes() {
      this.clientListOnUserEvn.forEach(client => {
        const clientConfiguration = client.configuration;
        if (!clientConfiguration || !clientConfiguration.service_types || !clientConfiguration.service_types.length) { // default
          const value = `${client.id}|${client.reference}|wellness`;
          if (this.serviceTypes.current.find(service => service.value === value)) {
            return;
          }
          this.availableClientProvidedServiceTypes.push({
            text: `${client.display_name} (ID: ${client.id}) - wellness`,
            value,
          });
          return;
        }
        clientConfiguration.service_types.forEach(service => {
          const value = `${client.id}|${client.reference}|${service}`;
          if (this.serviceTypes.current.find(currentService => currentService.value === value)) {
            return;
          }
          this.availableClientProvidedServiceTypes.push({
            text: `${client.display_name} (ID: ${client.id}) - ${service}`,
            value,
          });
        });
      });
      this.availableClientProvidedServiceTypes
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    addClientType() {
      /* eslint-disable */
      const selected = this.availableClientProvidedServiceTypes.find(item => item.value === this.currentSelectedServiceType);
      this.serviceTypes.current.push(selected);
      this.availableClientProvidedServiceTypes = this.availableClientProvidedServiceTypes
        .filter(item => item.value !== this.currentSelectedServiceType);
      this.currentSelectedServiceType = null;
      /* eslint-enable */
    },
    removeClientType(page) {
      this.serviceTypes.current = this.serviceTypes.current.filter(item => item.value !== page.value);
      this.availableClientProvidedServiceTypes.push(page);
    },
    async saveMemberInvitation() {
      this.saving = true;
      const payload = {};

      if (this.page.current.page_title !== this.page.original.page_title) {
        payload.title = this.page.current.page_title;
      }
      if (!isEqual(this.serviceTypes.current, this.serviceTypes.original)) {
        payload.targets = this.serviceTypes.current.map(item => item.value);
      }

      if (!isEmpty(payload)) {
        payload.uuid = this.page.current.page_uuid;
        const success = await this.$store.dispatch('Onboarding/updateMemberInvitationPage', {
          clientRef: this.client.reference,
          payload,
        });
        if (!success) {
          return;
        }
      }

      copyToClipboard(this.inviteUrl);
      this.$noty.success('Member invitation page updated with success, url copied to clipboard');
      this.$router.push(`/onboarding/clients/${this.client.id}/invitations`);
    },
    cancel() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to cancel?').then(value => {
        if (!value) {
          return;
        }
        this.$router.go(-1);
      });
    },
  },
};
</script>
